<template lang="pug">
  Section.cy-reservation-availability-period-section(
    required
    contains-dropdown
    :class="{ 'invalid-section': isInvalidSection }"
    :title="$t('plan_settings.basic_settings.sections.reservation_availability_period.section_name')"
  )
    .row.w-100.no-gutters
      .col
        AppDropdown.cy-reservation-availability-period-type(
          close-on-select
          order-direction="keep"
          :items="items"
          :value="selectedPeriodType"
          :class="{ invalid: periodTypeError }"
          @select="handleSelect"
        )
      .col.p-y-4
        .inputs
          .default-date(v-if="object.period_type.$model === default_")
            span {{ $t("plan_settings.basic_settings.period.before_departure") }}
            span.value {{ "1" }}
            span {{ $t("plan_settings.basic_settings.period.day") }}
            span.value {{ "0" }}
            span {{ $t("plan_settings.basic_settings.period.o_clock_earlier") }}
          .start-date.m-b-8(v-if="object.period_type.$model === startEndDate")
            span {{ $t("plan_settings.basic_settings.period.before_departure") }}
            AppNumberInput.value(
              v-model="object.start_day.$model"
              :invalid="startDayError"
            )
            span {{ startDateDaysLabel }}
            AppNumberInput.value(
              v-model="object.start_hour.$model"
              :invalid="startHourError"
              :max="24"
            )
            span {{ $t("plan_settings.basic_settings.period.o_clock_earlier") }}
          .end-date(v-if="object.period_type.$model === endDate || object.period_type.$model === startEndDate" )
            span {{ $t("plan_settings.basic_settings.period.before_departure") }}
            AppNumberInput.value(
              v-model="object.end_day.$model"
              :invalid="endDayError"
            )
            span {{ endDateDaysLabel }}
            AppNumberInput.value(
              v-model="object.end_hour.$model"
              :invalid="endHourError"
              :max="24"
            )
            span {{ $t("plan_settings.basic_settings.period.o_clock_earlier") }}
</template>

<script>
  import { DEFAULT, END_DATE, START_END_DATE } from "@/config/constants"
  import { resetValidatorValues } from "@/helpers/common"

  import { find } from "lodash-es"
  import { getReservationAvailabilityPeriodOptions } from "@/config/translations_helper"

  export default {
    props: {
      object: Object
    },

    data() {
      return {
        default_: DEFAULT,
        endDate: END_DATE,
        startEndDate: START_END_DATE
      }
    },

    computed: {
      items() {
        return getReservationAvailabilityPeriodOptions(this.$i18n)
      },

      selectedPeriodType() {
        return find(this.items, { id: this.object.period_type.$model })
      },

      periodTypeError() {
        return this.object.period_type.$error
      },

      startDayError() {
        return this.object.start_day.$error
      },

      startHourError() {
        return this.object.start_hour.$error
      },

      endDayError() {
        return this.object.end_day.$error
      },

      endHourError() {
        return this.object.end_hour.$error
      },

      isInvalidSection() {
        return (
          this.periodTypeError || this.startDayError || this.startHourError || this.endDayError || this.endHourError
        )
      },

      startDateDaysLabel() {
        return this.$t(`plan_settings.basic_settings.period.${this.object.start_day.$model > 1 ? "days" : "day"}`)
      },

      endDateDaysLabel() {
        return this.$t(`plan_settings.basic_settings.period.${this.object.end_day.$model > 1 ? "days" : "day"}`)
      }
    },

    components: {
      Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    methods: {
      handleSelect({ id }) {
        resetValidatorValues(this.object, ["start_day", "start_hour", "end_day", "end_hour"])

        this.object.period_type.$model = id
        this.object.$reset()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .row
    +styled-inputs

    .inputs
      display: flex
      justify-content: center
      flex-direction: column
      margin-left: 8px
      height: 100%

      .default-date,
      .start-date,
      .end-date
        align-items: center
        display: flex

        .value
          margin: 0 8px
</style>
